<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>Unit record with id: {{ $route.params.ID }} not found. </span>
      <span>
        <span>Check </span>
        <router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Unit
        </router-link>
      </span>
    </vs-alert>

    <vx-card>
      <div class="vx-row w-full">
        <div class="vx-col w-full lg:w-3/4">
          <div class="vx-row w-full">
            &nbsp;&nbsp;&nbsp;
            <div style="width: 200px;  padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("UnitModel") }}</label>
              <v-select
                label="name"
                class="mt-2 w-full"
                name="Name"
                v-model="unitModel.unitModelId"
                :reduce="(unitModel) => unitModel.id"
                :options="unitModelOptions"
                @input="getBuildingNumbersByModelId(unitModel.unitModelId)"
              />
            </div>


            <div style="width: 200px; margin:6px;padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("buildingnumber") }}</label>
              <v-select
                label="buildingName"
                class="w-full"
                name="buildingName"
                v-model="unitModel.buildingID"
                :reduce="(x) => x.id"
                :options="buildingNumberOptions"
                @input="getLevelsByBuldingId(unitModel.buildingID)"
              />
            </div>

            <div style="width: 200px; margin:6px;padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("Round") }}</label>
              <v-select
                v-model="unitModel.levelID"
                label="levelName"
                name="levelName"

                class="w-full"
                :reduce="(x) => x.id"
                :options="LevelsOptions"

                v-validate="'required'"
                @input="getUnitNumberByLevelId(unitModel.levelID)"
              />
            </div>

            <div style="width: 200px; margin:6px;padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("UnitNumber") }}</label>
              <vs-input
                v-model="unitModel.unitNumber"

                class="w-full"
                :data-vv-as="$t('UnitNumber')"
                name="unitNumber"
                v-validate="'required'"
              />
            </div>


            <div style="width: 200px; margin:5px; padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("UnitType") }}</label>
              <v-select
                label="nameAr"
                class="mt-2 w-full"
                name="nameAr"
                v-model="unitModel.unitTypeId"
                :reduce="(Type) => Type.id"
                :options="unittypes"
              />
            </div>

            <!-- <div class="vx-col lg:w-1/2">
              <label style="font-size: 16px;font-family:almarai ;">{{ $t("Owner") }}</label>
              <v-select
                label="nameAr"
                class="mt-2 w-full"
                name="nameAr"
                v-model="unitModel.unitOwnerId"
                :reduce="(Owner) => Owner.id"
                :options="Owners"
              />
            </div> -->





            <div style="width: 200px; margin:6px; padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{
                $t("FinancialPosition")
              }}</label>
              <v-select
                label="nameAr"
                class="mt-2 w-full"
                name="nameAr"
                v-model="unitModel.unitFinancialPositionStatusId"
                :reduce="(FinancialPosition) => FinancialPosition.id"
                :options="FinancialPositionStatus"
              />
            </div>
            <div style="width: 200px; margin:6px; padding:10px ;">
              <label style="font-size: 16px;font-family:almarai ;">{{
                $t("ConstructionUnitStatus")
              }}</label>
              <v-select
                label="nameAr"
                class="mt-2 w-full"
                name="nameAr"
                v-model="unitModel.constructionUnitStatusId"
                :reduce="(constractionStatus) => constractionStatus.id"
                :options="constractionStatus"
              />
            </div>


            <div style="width: 200px; margin:6px;padding:10px ;">
              <label  style="font-size: 16px;font-family:almarai ;">{{
                $t("DeliveryStatus")
              }}</label>
              <v-select
                label="nameAr"
                class="mt-2 w-full"
                name="nameAr"
                v-model="unitModel.unitDeliveryStatusId"
                :reduce="(DeliveryStatus) => DeliveryStatus.id"
                :options="DeliveryStatus"
              />
            </div>
          </div>
        </div>
        <!--
        <div class="vx-row w-full lg:w-1/3">
          <vs-card title="icon">
            <h4>{{ $t("IdImageURL") }}</h4>
            <template v-if="unitModel.idImageURL">
              <div
                class="img-container w-64 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + unitModel.IdImageURL"
                  class="responsive"
                />
              </div>
            </template>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
                >Update Image</vs-button
              >
              <vs-button
                type="flat"
                color="#999"
                @click="unitModel.IdImageURL = null"
                >Remove Image</vs-button
              >
            </div>
          </vs-card>
        </div> -->
      </div>
      <div class="vx-row" style="margin-right: 800px">
        <div style="margin-right: 10px">
          <vs-button  color="#10cd10" style="font-size: 16px;font-family:almarai;"
          :disabled='unitModel.unitDeliveryStatusId == null || unitModel.unitFinancialPositionStatusId == null ||
          unitModel.constructionUnitStatusId==null|| unitModel.unitTypeId == null||unitModel.unitNumber== null ||
          unitModel.levelID == null||unitModel.buildingID== null || unitModel.unitModelId == null'
          @click="addUnit">
            {{ $t("Save") }}
          </vs-button>
        </div>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <vs-button color="#eb5252" style="font-size: 16px;font-family:almarai;" @click="cancel">
            {{ $t("Cancel") }}
          </vs-button>
        </div>
      </div>
      <br>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "../../components/vx-card/VxCard.vue";
import { domain } from "@/gloabelConstant.js";
import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";
import moduleUintOwnerType from "@/store/UintOwnerType/moduleUintOwnerType.js";
import moduleUnitManagment from "@/store/UnitManagment/moduleUnitManagment.js";
import moduleUnitType from "@/store/UnitType/moduleUnitType.js";
import moduleUnitDeliveryStatus from "@/store/UnitDeliveryStatus/moduleUnitDeliveryStatus.js";
import moduleUnitFinancialPositionStatus from "@/store/UnitFinancialPositionStatus/moduleUnitFinancialPositionStatus.js";
import moduleConstructionUnitStatus from "@/store/ConstructionUnitStatus/moduleConstructionUnitStatus.js";
import moduleUintModel from "@/store/UintModel/moduleUintModel.js";

//import moduleBuilding from "@/store/Building/moduleBuilding.js";
import axios from "@/axios.js";

export default {
  components: { VxCard },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,
      activeTab: 0,
      search: {
        name: "",
        nationalId: "",
        phoneNumber: "",
        unitNumber: 0,
        unitOwnerTypeId: 0,
        unitModelId: 0,
        buildingID: 0,
        levelID: 0,
        pageNumber: 0,
        pageSize: 10000,
      },
      unitModel: {
        buildingID: null,
        UnitId: null,
        levelID: null,
        IDD:0,

        constructionUnitStatusId: null,
        unitDeliveryStatusId: null,
        unitTypeId: null,
        unitOwnerId : null,
        unitFinancialPositionStatusId: null,
        unitModelId: null,
      },
      Owners: [],
      unittypes: [],
      constractionStatus: [],
      DeliveryStatus: [],
      FinancialPositionStatus: [],
      unitModelOptions: [],
      buildingNumberOptions: [],
      LevelsOptions: [],
    };
  },
  computed: {
    // UintOwnerTypes(){
    //   return this.$store.state.unitOwnerTypeList.UintOwnerTypes;
    // }
  },
  methods: {

    getBuildingNumbersByModelId(modelId) {
      debugger;
      if (!modelId) return;

      try {
        const response = axios.get("API/UnitData/GetBuildingByModelId?Id="+modelId)
        .then((res) => {
        debugger;
        this.buildingNumberOptions = res.data.data;
        console.log(this.buildingNumberOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const BuildingNames = data.data.map(item => item.BuildingName);
          this.buildingNumberOptions = BuildingNames;
        } else {
          this.buildingNumberOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },
    getLevelsByBuldingId(buldingId) {
      debugger;
      if (!buldingId) return;

      try {

        const response = axios.get("API/UnitData/GetlevelsByBuildingId?Id="+buldingId)
        .then((res) => {
        debugger;
        this.LevelsOptions = res.data.data;
        console.log(this.LevelsOptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const LevelsOptions = data.data.map(item => item.LevelsOptions);
          this.LevelsOptions = LevelsOptions;
        } else {
          this.LevelsOptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },
    getUnitNumberByLevelId(levelId) {
      debugger;
      if (!levelId) return;

      try {

        const response = axios.get("API/UnitData/GetUnitDataBylevelsId?Id="+levelId)
        .then((res) => {
        debugger;
        this.UnitNumberoptions = res.data.data;
        console.log(this.UnitNumberoptions);
      });
        const data =  response.json();

        if (data.message === "Data list: ") {
          const UnitNumberoptions = data.data.map(item => item.UnitNumberoptions);
          this.UnitNumberoptions = UnitNumberoptions;
        } else {
          this.UnitNumberoptions = [];
          console.log("No data found");
        }
      } catch (error) {
        console.error("Error fetching building numbers:", error);
      }
    },



    fetchmUintModelOptions() {
      debugger;
      this.$store
        .dispatch("moduleUintModelList/fetchDataListItems")
        .then((res) => {
          debugger;
          this.unitModelOptions = res.data.data;
        })
        .catch((error) => {
          console.error("Error fetching unit model options:", error);
        });
    },
    handleSearch() {
      debugger;
      this.$store
        .dispatch("AdsManagementList/SearchUnitOwner", this.search)
        .then((res) => {
          debugger;
          this.Owners = res.data.data;
        });
    },
    addUnit() {
      debugger;
      //this.unitModel.nameEn = this.unitModel.nameAr;
      //this.unitModel.UnitDescraptionEn = this.unitModel.UnitDescraptionAr;

      const IDD = this.$route.params.ID;
      debugger;
      if (IDD !== null && IDD > 0) {
        this.$store
          .dispatch("UnitManagmentList/updateItem", this.unitModel)
          .then(() => {
            debugger;
            window.showUpdateSuccess();
            this.$vs.loading.close();

            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showUpdateFailed(err.response.data.message);
            } else {
              window.showUpdateFailed("");
            }
            this.$vs.loading.close();
          });
      } else {
        debugger;
        this.$store
          .dispatch("UnitManagmentList/addItem", this.unitModel)
          .then(() => {
            debugger;
            window.showAddSuccess();
            this.initValues();
            this.$vs.loading.close();
            this.cancle();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            this.$vs.loading.close();
          });
      }
    },
    reset_data() {
      this.initValues();
    },

    initValues() {
      this.unitModel = {};
    },
    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("AdsManagementList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              this.unitModel.IdImageURL = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },

    // removeImage(item) {
    //   var index = this.unitModel.NewsGalleries.findIndex(
    //     (x) => x.AttachPath == item.AttachPath
    //   );
    //   this.NewsModel.NewsGalleries.splice(index, 1);
    //   if (item.ID > 0) this.$store.dispatch("unitModel/DeleteAttachmenByID", item.ID);
    // },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleUintModel.isRegistered) {
      this.$store.registerModule("moduleUintModelList", moduleUintModel);
      moduleUintModel.isRegistered = true;
    }

    if (!moduleConstructionUnitStatus.isRegistered) {
      this.$store.registerModule(
        "UnitConstructionUnitStatusList",
        moduleConstructionUnitStatus
      );
      moduleConstructionUnitStatus.isRegistered = true;
    }

    if (!moduleUnitFinancialPositionStatus.isRegistered) {
      this.$store.registerModule(
        "UnitFinancialPositionStatusList",
        moduleUnitFinancialPositionStatus
      );
      moduleUnitFinancialPositionStatus.isRegistered = true;
    }

    if (!moduleUnitDeliveryStatus.isRegistered) {
      this.$store.registerModule(
        "UnitDeliveryStatusList",
        moduleUnitDeliveryStatus
      );
      moduleUnitDeliveryStatus.isRegistered = true;
    }

    if (!moduleUnitType.isRegistered) {
      this.$store.registerModule("UintTypeList", moduleUnitType);
      moduleUnitType.isRegistered = true;
    }

    if (!moduleUintOwnerType.isRegistered) {
      this.$store.registerModule("UintOwnerTypeList", moduleUintOwnerType);
      moduleUintOwnerType.isRegistered = true;
    }

    if (!moduleUnitManagment.isRegistered) {
      this.$store.registerModule("UnitManagmentList", moduleUnitManagment);
      moduleUnitManagment.isRegistered = true;
    }

    this.$store.dispatch("UintOwnerTypeList/fetchDataListItems").then((res) => {
debugger;
      this.unitOwnerTypeList = res.data.data;
    });

    //FinancialPositionStatus
    this.$store
      .dispatch("UnitFinancialPositionStatusList/fetchDataListItems")
      .then((res) => {
        this.FinancialPositionStatus = res.data.data;
      });

    this.$store
      .dispatch("UnitDeliveryStatusList/fetchDataListItems")
      .then((res) => {
        this.DeliveryStatus = res.data.data;
      });

    this.$store.dispatch("UintTypeList/fetchDataListItems").then((res) => {
      this.unittypes = res.data.data;
    });

    this.$store
      .dispatch("UnitConstructionUnitStatusList/fetchDataListItems")
      .then((res) => {
        this.constractionStatus = res.data.data;
      });

    if (!moduleAdsManagement.isRegistered) {
      this.$store.registerModule(
        "AdsManagementList",
        moduleAdsManagement
      );
      moduleAdsManagement.isRegistered = true;
    }
    this.handleSearch(this.search);
    debugger;
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined && ID != 0)
    {
      debugger;
      this.$store.dispatch("UnitManagmentList/GetItemByID", ID).then((res) => {
        debugger;
        this.unitModel = res.data.data;
        this.getBuildingNumbersByModelId(this.unitModel.unitModelId);
        this.getLevelsByBuldingId(this.unitModel.buildingID)
        debugger;
        console.log(this.unitModel);
      });

    }
    else if(ID== undefined)
    {
      ID ==0 ;
    }

    this.fetchmUintModelOptions();
  },
  mounted() {
    this.isMounted = true;
    this.fetchmUintModelOptions();
  },
};
</script>

<style>
/* Add any additional styling here */
</style>
